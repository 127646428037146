.ql-container {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  position: relative;
}

.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}

.ql-container.ql-disabled .ql-editor ul[data-checked] > li:before {
  pointer-events: none;
}

.ql-clipboard {
  height: 1px;
  position: absolute;
  top: 50%;
  left: -100000px;
  overflow-y: hidden;
}

.ql-clipboard p {
  margin: 0;
  padding: 0;
}

.ql-editor {
  box-sizing: border-box;
  height: 100%;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  padding: 12px 15px;
  line-height: 1.42;
  overflow-y: auto;
}

.ql-editor > * {
  cursor: text;
}

.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding: 0;
}

.ql-editor ol, .ql-editor ul {
  padding-left: 1.5em;
}

.ql-editor ol > li, .ql-editor ul > li {
  list-style-type: none;
}

.ql-editor ul > li:before {
  content: "•";
}

.ql-editor ul[data-checked="true"], .ql-editor ul[data-checked="false"] {
  pointer-events: none;
}

.ql-editor ul[data-checked="true"] > li *, .ql-editor ul[data-checked="false"] > li * {
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before, .ql-editor ul[data-checked="false"] > li:before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}

.ql-editor ul[data-checked="true"] > li:before {
  content: "☑";
}

.ql-editor ul[data-checked="false"] > li:before {
  content: "☐";
}

.ql-editor li:before {
  white-space: nowrap;
  width: 1.2em;
  display: inline-block;
}

.ql-editor li:not(.ql-direction-rtl):before {
  text-align: right;
  margin-left: -1.5em;
  margin-right: .3em;
}

.ql-editor li.ql-direction-rtl:before {
  margin-left: .3em;
  margin-right: -1.5em;
}

.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.ql-editor ol li.ql-direction-rtl, .ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}

.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}

.ql-editor ol li:before {
  content: counter(list-0, decimal) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}

.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}

.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}

.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}

.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}

.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}

.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}

.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}

.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}

.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}

.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}

.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}

.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}

.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}

.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}

.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}

.ql-editor .ql-video {
  max-width: 100%;
  display: block;
}

.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}

.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}

.ql-editor .ql-bg-black {
  background-color: #000;
}

.ql-editor .ql-bg-red {
  background-color: #e60000;
}

.ql-editor .ql-bg-orange {
  background-color: #f90;
}

.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}

.ql-editor .ql-bg-green {
  background-color: #008a00;
}

.ql-editor .ql-bg-blue {
  background-color: #06c;
}

.ql-editor .ql-bg-purple {
  background-color: #93f;
}

.ql-editor .ql-color-white {
  color: #fff;
}

.ql-editor .ql-color-red {
  color: #e60000;
}

.ql-editor .ql-color-orange {
  color: #f90;
}

.ql-editor .ql-color-yellow {
  color: #ff0;
}

.ql-editor .ql-color-green {
  color: #008a00;
}

.ql-editor .ql-color-blue {
  color: #06c;
}

.ql-editor .ql-color-purple {
  color: #93f;
}

.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}

.ql-editor .ql-size-small {
  font-size: .75em;
}

.ql-editor .ql-size-large {
  font-size: 1.5em;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}

.ql-editor .ql-align-center {
  text-align: center;
}

.ql-editor .ql-align-justify {
  text-align: justify;
}

.ql-editor .ql-align-right {
  text-align: right;
}

.ql-editor.ql-blank:before {
  color: #0009;
  content: attr(data-placeholder);
  pointer-events: none;
  font-style: italic;
  position: absolute;
  left: 15px;
  right: 15px;
}

.ql-snow.ql-toolbar:after, .ql-snow .ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  cursor: pointer;
  float: left;
  height: 24px;
  width: 28px;
  background: none;
  border: none;
  padding: 3px 5px;
  display: inline-block;
}

.ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%;
}

.ql-snow.ql-toolbar .ql-icon-picker svg, .ql-snow .ql-toolbar .ql-icon-picker svg {
  height: 100%;
}

.ql-snow.ql-toolbar .ql-formats:last-child, .ql-snow .ql-toolbar .ql-formats:last-child {
  margin-right: 0;
}

.ql-snow.ql-toolbar .ql-formats:last-child:active:hover, .ql-snow .ql-toolbar .ql-formats:last-child:active:hover {
  outline: none;
}

.ql-snow.ql-toolbar input.ql-image[type="file"], .ql-snow .ql-toolbar input.ql-image[type="file"] {
  display: none;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar :not(.ql-picker-options) > .ql-picker-item.ql-selected, .ql-snow .ql-toolbar :not(.ql-picker-options) > .ql-picker-item.ql-selected {
  opacity: 1;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button, .ql-snow.ql-toolbar .ql-picker-label, .ql-snow .ql-toolbar .ql-picker-label, .ql-snow.ql-toolbar .ql-picker-item, .ql-snow .ql-toolbar .ql-picker-item {
  color: var(--color);
  opacity: .4;
  outline: none;
  transition: color .3s cubic-bezier(.4, 0, .2, 1);
}

.ql-snow.ql-toolbar button .ql-fill, .ql-snow .ql-toolbar button .ql-fill, .ql-snow.ql-toolbar .ql-picker-label .ql-fill, .ql-snow .ql-toolbar .ql-picker-label .ql-fill, .ql-snow.ql-toolbar .ql-picker-item .ql-fill, .ql-snow .ql-toolbar .ql-picker-item .ql-fill, .ql-snow.ql-toolbar button .ql-stroke.ql-fill, .ql-snow .ql-toolbar button .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item .ql-stroke.ql-fill {
  fill: var(--color);
  transition: fill .3s cubic-bezier(.4, 0, .2, 1);
}

.ql-snow.ql-toolbar button .ql-stroke, .ql-snow .ql-toolbar button .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item .ql-stroke, .ql-snow.ql-toolbar button .ql-stroke-miter, .ql-snow .ql-toolbar button .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item .ql-stroke-miter {
  stroke: var(--color);
  transition: stroke .3s cubic-bezier(.4, 0, .2, 1);
}

.ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg, .ql-snow.ql-toolbar .ql-picker-label svg, .ql-snow .ql-toolbar .ql-picker-label svg, .ql-snow.ql-toolbar .ql-picker-item svg, .ql-snow .ql-toolbar .ql-picker-item svg {
  width: 100%;
}

@media (pointer: coarse) {
  .ql-snow.ql-toolbar button:hover:not(.ql-active), .ql-snow .ql-toolbar button:hover:not(.ql-active) {
    color: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: #444;
  }

  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: #444;
  }
}

.ql-snow, .ql-snow * {
  box-sizing: border-box;
}

.ql-snow .ql-hidden {
  display: none;
}

.ql-snow .ql-out-bottom, .ql-snow .ql-out-top {
  visibility: hidden;
}

.ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}

.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none;
}

.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-snow .ql-formats {
  vertical-align: middle;
  display: inline-block;
}

.ql-snow .ql-formats:after {
  clear: both;
  content: "";
  display: table;
}

.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
  fill: #444;
}

.ql-snow .ql-empty {
  fill: none;
}

.ql-snow .ql-even {
  fill-rule: evenodd;
}

.ql-snow .ql-thin, .ql-snow .ql-stroke.ql-thin {
  stroke-width: 1px;
}

.ql-snow .ql-transparent {
  opacity: .4;
}

.ql-snow .ql-direction svg:last-child {
  display: none;
}

.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}

.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}

.ql-snow .ql-editor h1 {
  font-size: 2em;
}

.ql-snow .ql-editor h2 {
  font-size: 1.5em;
}

.ql-snow .ql-editor h3 {
  font-size: 1.17em;
}

.ql-snow .ql-editor h4 {
  font-size: 1em;
}

.ql-snow .ql-editor h5 {
  font-size: .83em;
}

.ql-snow .ql-editor h6 {
  font-size: .67em;
}

.ql-snow .ql-editor a {
  text-decoration: underline;
}

.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 16px;
}

.ql-snow .ql-editor code, .ql-snow .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}

.ql-snow .ql-editor pre {
  white-space: pre-wrap;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.ql-snow .ql-editor code {
  padding: 2px 4px;
  font-size: 85%;
}

.ql-snow .ql-editor pre.ql-syntax {
  color: #f8f8f2;
  background-color: #23241f;
  overflow: visible;
}

.ql-snow .ql-editor img {
  max-width: 100%;
}

.ql-snow .ql-picker {
  color: #444;
  float: left;
  height: 24px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding-left: 8px;
  padding-right: 2px;
  display: inline-block;
  position: relative;
}

.ql-snow .ql-picker-label:before {
  line-height: 22px;
  display: inline-block;
}

.ql-snow .ql-picker-options {
  background-color: var(--bgcolor-overlay);
  min-width: 100%;
  white-space: nowrap;
  padding: 4px 8px;
  transition: background-color .3s cubic-bezier(.4, 0, .2, 1);
  display: none;
  position: absolute;
}

.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: var(--color);
  opacity: 1;
  z-index: 2;
  transition: color .3s cubic-bezier(.4, 0, .2, 1);
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: var(--color);
  transition: color .3s cubic-bezier(.4, 0, .2, 1);
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: var(--color);
  transition: color .3s cubic-bezier(.4, 0, .2, 1);
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 1;
  margin-top: -1px;
  display: block;
  top: 100%;
}

.ql-snow .ql-color-picker, .ql-snow .ql-icon-picker {
  width: 28px;
}

.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-label svg, .ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}

.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0;
}

.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;
  width: 24px;
  padding: 2px 4px;
}

.ql-snow .ql-color-picker .ql-picker-options {
  width: 152px;
  padding: 3px 5px;
}

.ql-snow .ql-color-picker .ql-picker-item {
  float: left;
  height: 16px;
  width: 16px;
  border: 1px solid #0000;
  border-radius: 5px;
  margin: 2px;
  padding: 0;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  width: 18px;
  height: 18px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  right: 0;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=""]):before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=""]):before {
  content: attr(data-label);
}

.ql-snow .ql-picker.ql-header {
  width: 98px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label:before, .ql-snow .ql-picker.ql-header .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  content: "Heading 1";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  content: "Heading 2";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  content: "Heading 3";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  content: "Heading 4";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  content: "Heading 5";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]:before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  content: "Heading 6";
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  font-size: 2em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  font-size: 1.5em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  font-size: 1.17em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  font-size: 1em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  font-size: .83em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  font-size: .67em;
}

.ql-snow .ql-picker.ql-font {
  width: 108px;
}

.ql-snow .ql-picker.ql-font .ql-picker-label:before, .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  content: "Sans Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  content: "Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]:before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  content: "Monospace";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  font-family: Georgia, Times New Roman, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  font-family: Monaco, Courier New, monospace;
}

.ql-snow .ql-picker.ql-size {
  width: 98px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label:before, .ql-snow .ql-picker.ql-size .ql-picker-item:before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  content: "Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]:before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  font-size: 32px;
}

.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: #fff;
}

.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: #000;
}

.ql-toolbar.ql-snow {
  box-sizing: border-box;
  padding: 8px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid #0000;
}

.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid #0000;
  box-shadow: 0 2px 8px #0003;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label, .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #0000;
}

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected, .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0;
}

.ql-snow .ql-tooltip {
  color: #444;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #0000;
  padding: 5px 12px;
  box-shadow: 0 0 5px #ddd;
}

.ql-snow .ql-tooltip:before {
  content: "Visit URL:";
  margin-right: 8px;
  line-height: 26px;
}

.ql-snow .ql-tooltip input[type="text"] {
  height: 26px;
  width: 170px;
  border: 1px solid #0000;
  margin: 0;
  padding: 3px 5px;
  font-size: 13px;
  display: none;
}

.ql-snow .ql-tooltip a.ql-preview {
  max-width: 200px;
  text-overflow: ellipsis;
  vertical-align: top;
  display: inline-block;
  overflow-x: hidden;
}

.ql-snow .ql-tooltip a.ql-action:after {
  content: "Edit";
  border-right: 1px solid #0000;
  margin-left: 16px;
  padding-right: 8px;
}

.ql-snow .ql-tooltip a.ql-remove:before {
  content: "Remove";
  margin-left: 8px;
}

.ql-snow .ql-tooltip a {
  line-height: 26px;
}

.ql-snow .ql-tooltip.ql-editing a.ql-preview, .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.ql-snow .ql-tooltip.ql-editing input[type="text"] {
  display: inline-block;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action:after {
  content: "Save";
  border-right: 0;
  padding-right: 0;
}

.ql-snow .ql-tooltip[data-mode="link"]:before {
  content: "Enter link:";
}

.ql-snow .ql-tooltip[data-mode="formula"]:before {
  content: "Enter formula:";
}

.ql-snow .ql-tooltip[data-mode="video"]:before {
  content: "Enter video:";
}

.ql-toolbar.ql-snow {
  box-sizing: border-box;
  padding: 8px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid #0000;
}

.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid #0000;
  box-shadow: 0 2px 8px #0003;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label, .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #0000;
}

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected, .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0;
}

.ql-snow .ql-tooltip {
  color: #444;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #0000;
  padding: 5px 12px;
  box-shadow: 0 0 5px #ddd;
}

.ql-snow .ql-tooltip:before {
  content: "Visit URL:";
  margin-right: 8px;
  line-height: 26px;
}

.ql-snow .ql-tooltip input[type="text"] {
  height: 26px;
  width: 170px;
  border: 1px solid #0000;
  margin: 0;
  padding: 3px 5px;
  font-size: 13px;
  display: none;
}

.ql-snow .ql-tooltip a.ql-preview {
  max-width: 200px;
  text-overflow: ellipsis;
  vertical-align: top;
  display: inline-block;
  overflow-x: hidden;
}

.ql-snow .ql-tooltip a.ql-action:after {
  content: "Edit";
  border-right: 1px solid #0000;
  margin-left: 16px;
  padding-right: 8px;
}

.ql-snow .ql-tooltip a.ql-remove:before {
  content: "Remove";
  margin-left: 8px;
}

.ql-snow .ql-tooltip a {
  line-height: 26px;
}

.ql-snow .ql-tooltip.ql-editing a.ql-preview, .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.ql-snow .ql-tooltip.ql-editing input[type="text"] {
  display: inline-block;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action:after {
  content: "Save";
  border-right: 0;
  padding-right: 0;
}

.ql-snow .ql-tooltip[data-mode="link"]:before {
  content: "Enter link:";
}

.ql-snow .ql-tooltip[data-mode="formula"]:before {
  content: "Enter formula:";
}

.ql-snow .ql-tooltip[data-mode="video"]:before {
  content: "Enter video:";
}

.ql-container.ql-snow {
  border: none;
}

body {
  background-color: var(--bgcolor);
  margin: 0;
  transition: background-color .3s cubic-bezier(.4, 0, .2, 1);
}

html[data-theme="dark"] {
  --bgcolor: #151519;
  --bgcolor-overlay: #202127;
  --color: #fafafa;
  --logo-dark: #fafafa;
  --logo-mid: #bdbdbd;
  --logo-light: #969696;
  --dialog-shadow: 0 11px 15px -7px #0003;
}

html[data-theme="light"] {
  --bgcolor: #fafafa;
  --bgcolor-overlay: #f0f0f0;
  --color: #272727;
  --logo-dark: #272727;
  --logo-mid: #424242;
  --logo-light: #696969;
  --dialog-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
}

html {
  background-color: var(--bg);
  color: var(--color);
}

.no-transition, .no-transition *, .no-transition :before, .no-transition :after {
  transition: none !important;
}

p, h1, #note {
  color: var(--color);
  font-family: Rubik;
  transition: color .3s cubic-bezier(.4, 0, .2, 1);
}

a {
  color: #006edc;
}

.icons {
  pointer-events: none;
  margin: 8px;
  position: absolute;
  top: 0;
  right: 0;
}

.icons .icon {
  pointer-events: all;
  display: block;
  position: relative;
}

.icons .icon .tooltip {
  color: var(--color);
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transform-origin: 100%;
  margin: 0;
  font-size: 14px;
  transition: opacity .15s cubic-bezier(.4, 0, .2, 1), transform .15s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translate(10px, -50%);
}

.icons .icon:hover .tooltip {
  opacity: 1;
  transform: translate(0, -50%);
}

.icons .icon svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: .65;
  fill: var(--color);
  padding: 8px;
  transition: fill .3s cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.icons .icon:active svg {
  opacity: .8;
}

.icons .extension {
  display: none;
}

.icons .extension.visible {
  display: block;
}

@media (max-width: 600px) {
  .icons .extension.visible {
    display: none;
  }
}

.icons #chrome-extension-icon svg {
  transform: scale(.85);
}

.dialog-container {
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  transition: opacity .15s cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: fixed;
}

.dialog-container > * {
  -webkit-user-select: text;
  user-select: text;
}

.dialog-container.visible {
  opacity: 1;
  pointer-events: all;
}

.dialog-container .dialog {
  background-color: var(--bgcolor-overlay);
  width: 600px;
  max-width: calc(100% - 150px);
  max-height: calc(100% - 100px);
  box-shadow: var(--dialog-shadow);
  border-radius: 5px;
  padding: 25px;
  transition: background-color .3s cubic-bezier(.4, 0, .2, 1);
  overflow-y: scroll;
}

.dialog-container .dialog h1 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
}

.dialog-container .dialog h1:not(:first-child) {
  margin-top: 25px;
}

.dialog-container .dialog p {
  margin-top: 0;
  margin-bottom: 5px;
}

.dialog-container .dialog .checkbox-container {
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  margin: 10px 0;
  display: flex;
}

.dialog-container .dialog .checkbox-container input {
  visibility: hidden;
  height: 0;
  width: 0;
}

.dialog-container .dialog .checkbox-container .checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: relative;
}

.dialog-container .dialog .checkbox-container .checkbox:after {
  content: "";
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 2px solid;
  border-color: var(--color);
  border-radius: 2px;
  transition: border-color .3s cubic-bezier(.4, 0, .2, 1), opacity .15s cubic-bezier(.4, 0, .2, 1), transform .15s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
}

.dialog-container .dialog .checkbox-container .checkbox svg {
  width: 100%;
  height: 100%;
  opacity: 0;
  fill: var(--color);
  border-radius: 2px;
  transition: fill .3s cubic-bezier(.4, 0, .2, 1), opacity .15s cubic-bezier(.4, 0, .2, 1), transform .15s cubic-bezier(.4, 0, .2, 1);
  display: block;
  transform: scale(.325);
}

.dialog-container .dialog .checkbox-container input:checked ~ .checkbox:after {
  opacity: 0;
  transform: scale(1.3);
}

.dialog-container .dialog .checkbox-container input:checked ~ .checkbox svg {
  opacity: 1;
  transform: scale(1);
}

.dialog-container .dialog .checkbox-container p {
  cursor: pointer;
  margin: 0;
  padding-left: 5px;
}

.center {
  width: 100%;
  height: 100%;
  min-width: 350px;
  box-sizing: border-box;
  flex-direction: column;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  position: fixed;
  overflow-y: auto;
}

@media (max-width: 600px) {
  .center {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (max-width: 500px) {
  .center {
    padding-left: 0;
    padding-right: 0;
  }
}

.center div.ql-toolbar {
  border-radius: 5px;
  margin: 8px auto 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: none;
}

@media (max-width: 600px) {
  .center div.ql-toolbar {
    margin-left: 0;
  }
}

@media (max-width: 500px) {
  .center div.ql-toolbar {
    padding-right: 50px;
  }

  .center div.ql-toolbar .ql-formats {
    margin-right: 5px;
  }
}

.center div.ql-toolbar.visible {
  display: block;
}

.center div.ql-toolbar .ql-picker-label {
  outline: none;
}

.center #note {
  height: auto;
  min-width: 500px;
  max-width: 750px;
  margin: auto;
  font-family: Rubik;
  font-size: 15px;
}

@media (max-width: 600px) {
  .center #note {
    min-width: 100%;
    font-size: 14px;
  }
}

.center #note .ql-editor {
  background-color: var(--bgcolor-overlay);
  border-radius: 5px;
  transition: background-color .3s cubic-bezier(.4, 0, .2, 1);
}

@media (max-width: 500px) {
  .center #note .ql-editor {
    border-radius: 0;
  }
}

.center #note .ql-editor:before {
  color: var(--color);
  opacity: .5;
  transition: color .3s cubic-bezier(.4, 0, .2, 1);
}

/*# sourceMappingURL=index.a513c41b.css.map */
