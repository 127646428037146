@import './variables.styl'
@import './quill-styles/snow.styl'
body
  margin: 0px
  transition: background-color transition-color
  background-color var(--bgcolor)

darkModeDialogShadow = 0 11px 15px -7px rgba(0,0,0,.2)
html[data-theme="dark"]
  --bgcolor: #151519
  --bgcolor-overlay: #202127
  --color: #fafafa
  --logo-dark: #fafafa
  --logo-mid: #bdbdbd
  --logo-light: #969696
  --dialog-shadow: darkModeDialogShadow

html[data-theme="light"]
  --bgcolor: #fafafa
  --bgcolor-overlay: #F0F0F0
  --color: #272727
  --logo-dark: #272727
  --logo-mid: #424242
  --logo-light: #696969
  --dialog-shadow: darkModeDialogShadow, 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12)

html {
  background-color: var(--bg);
  color: var(--color);
}

.no-transition
  transition: none !important
  *, ::before, ::after
    transition: none !important
p, h1, #note
  font-family: Rubik
  transition: color transition-color
  color: var(--color)
activeColor = #006edc
a
  color: activeColor
topcontent-margin = 8px
topcontent-padding = 8px
.icons
  position: absolute
  top: 0px
  right: 0px
  margin: topcontent-margin
  pointer-events: none
  .icon
    display: block
    pointer-events: all
    position: relative
    .tooltip
      font-size: 14px
      color: var(--color)
      position: absolute
      top: 50%
      right: 100%
      white-space: nowrap
      margin: 0px
      pointer-events: none
      transition: opacity transition, transform transition
      opacity: 0
      transform-origin: right
      transform: translate(10px, -50%)
    &:hover .tooltip
      opacity: 1
      transform: translate(0px, -50%)
    svg
      display: block
      padding: topcontent-padding
      width: 24px
      height: 24px
      cursor: pointer
      transition: fill transition-color
      opacity: 0.65
      fill: var(--color)
    &:active svg
      opacity: calc(0.8)
  .extension
    display: none
    &.visible
      display: block
      @media (max-width: 600px)
        display: none
  #chrome-extension-icon
    svg
      transform: scale(0.85)
.dialog-container
  width: 100%
  height: 100%
  position: fixed
  justify-content: center
  align-items: center
  display: flex
  opacity: 0
  pointer-events: none
  background-color: rgba(#000000, 0.5)
  transition: opacity transition
  user-select: none
  font-size: 15px
  > *
    user-select: text
  &.visible
    opacity: 1
    pointer-events: all
  .dialog
    transition: background-color transition-color
    background-color var(--bgcolor-overlay)
    width: 600px
    max-width: calc(100% - 150px)
    max-height: calc(100% - 100px)
    padding: 25px
    border-radius: 5px
    box-shadow: var(--dialog-shadow)
    overflow-y: scroll
    h1
      font-weight: 500
      font-size: 20px
      margin-top: 0px
      margin-bottom: 15px
    h1:not(:first-child)
      margin-top: 25px
    p
      margin-top: 0px
      margin-bottom: 5px
    .checkbox-container
      display: flex
      align-items: center
      margin: 10px 0px
      user-select: none
      input
        visibility: hidden
        height: 0px
        width: 0px
      .checkbox
        width: 18px
        height: 18px
        cursor: pointer
        position: relative
        &::after
          content: ''
          position: absolute
          box-sizing: border-box
          width: 100%
          height: 100%
          top: 0px
          left: 0px
          border: 2px solid
          border-color: var(--color)
          border-radius: 2px
          transition: border-color transition-color, opacity transition, transform transition
        svg
          display: block
          transition: fill transition-color, opacity transition, transform transition
          width: 100%
          height: 100%
          opacity: 0
          border-radius: 2px
          fill: var(--color)
          transform: scale(calc(1.3*0.25))
      input:checked ~ .checkbox
        &::after
          opacity: 0
          transform: scale(1.3)
        svg
          opacity: 1
          transform: scale(1)
      p
        margin: 0px
        padding-left: 5px
        cursor: pointer

.center
  position: fixed
  overflow-y: auto
  width: 100%
  height: 100%
  min-width: 350px
  box-sizing border-box
  padding-left: 24px
  padding-right: 24px
  padding-bottom: 24px
  display: flex
  flex-direction: column
  @media (max-width: 600px)
    padding-left: 12px
    padding-right: 12px
  @media (max-width: 500px)
    padding-left: 0px
    padding-right: 0px
  div.ql-toolbar
    display: none
    margin-left: auto
    margin-right: auto
    margin-top: topcontent-margin
    margin-bottom: 5px
    border-radius: 5px
    padding-top: topcontent-padding
    padding-bottom: topcontent-padding
    @media (max-width: 600px)
      margin-left: 0px
    @media (max-width: 500px)
      padding-right: 50px
      .ql-formats
        margin-right: 5px
    &.visible
      display: block
    .ql-picker-label
      outline: none
  #note
    height: auto
    min-width: 500px
    max-width: 750px
    margin: auto
    font-family: 'Rubik'
    font-size: 15px
    @media (max-width: 600px)
      font-size: 14px
      min-width: 100%
    .ql-editor
      background-color: var(--bgcolor-overlay)
      border-radius: 5px
      transition: background-color transition-color
      @media (max-width: 500px)
        border-radius: 0px
      &::before
        transition: color transition-color
        color: var(--color)
        opacity: 0.5
